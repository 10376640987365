<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <div class="search">
      <avue-form ref="searchForm" v-model="searchData" :option="searchOption" @submit="searchChange" @reset-change="searchReset">
        <template slot="menuForm">
          <el-button icon="el-icon-download" @click="searchExcel()">导出</el-button>
        </template>
      </avue-form>
    </div>
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <!-- <el-button type="primary" v-has="[dataApi.getList]" size="small">测试{{dataApi.getList.p}}</el-button>
        <el-button icon="el-icon-check" type="primary" size="small" @click="addDialogForm">新增报单</el-button> -->
      </template>
      <template slot-scope="{type,size,row,index}" slot="menu">
        <el-button icon="el-icon-view" :size="size" :type="type" @click.stop="rowView(row)">查看</el-button>
        <el-button icon="el-icon-edit-outline" v-if="row.status === 2" :size="size" :type="type" @click.stop="addDialogForm(row,index)">审核</el-button>
        <el-button icon="el-icon-delete" 
          :size="size" 
          v-if="row.status === 1 || row.status === 2" 
          :type="type"
          @click.stop="rowDel(row,index)">作废</el-button>
      </template>
    </avue-crud>
    <el-drawer
      title="交易详情"
      :before-close="drawerInfoClose"
      :visible.sync="drawerInfoVisible"
      direction="ttb"
      size="100%"
    >
      <div class="details">
        <!--交易详情-->
        <div class="details-header">
          <div class="details-title">基础信息</div>
        </div>
        <div class="details-view">
          <div class="details-view-item">
            <div class="details-view-item-label">流水号:</div>
            <div class="details-view-item-value">{{formDa.batch}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">推荐人:</div>
            <div class="details-view-item-value">{{formDa.referrer_name}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">供应商:</div>
            <div class="details-view-item-value">{{formDa.bank.title}}</div>
          </div>
           <div class="details-view-item">
            <div class="details-view-item-label">贷款产品:</div>
            <div class="details-view-item-value">{{formDa.product.title}}</div>
          </div>
           <div class="details-view-item">
            <div class="details-view-item-label">佣金规则:</div>
            <div class="details-view-item-value">{{formDa.rebate_rule}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">公司名称:</div>
            <div class="details-view-item-value">{{formDa.company}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">贷款人:</div>
            <div class="details-view-item-value">{{formDa.guest}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">贷款金额:</div>
            <div class="details-view-item-value">￥{{formDa.amount}}万元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">状态:</div>
            <div class="details-view-item-value">{{formDa.status_text}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">客服编号:</div>
            <div class="details-view-item-value">{{formDa.services}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">操作员:</div>
            <div class="details-view-item-value">{{formDa.update_user}}</div>
          </div>
          
          <div class="details-view-item">
            <div class="details-view-item-label">备注:</div>
            <div class="details-view-item-value">{{formDa.remark}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">创建时间:</div>
            <div class="details-view-item-value">{{formDa.create_time}}</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">授信金额:</div>
            <div class="details-view-item-value">￥{{formDa.guestInfo.bank_credit}}万元</div>
          </div>
          <div class="details-view-item">
            <div class="details-view-item-label">更新日期:</div>
            <div class="details-view-item-value">{{formDa.update_time}}</div>
          </div>
          <div class="details-view-img">
            <div class="details-view-img-label">佐证材料:</div>
            <div class="details-view-item-value">

              <el-image style="width: 120px; height: 120px; margin-right:20px"
                v-for="(item, index) in formDa.material" :key="index"
                :src="item" 
                :preview-src-list="formDa.material">
              </el-image>
            </div>
          </div>
        </div>
        <div class="details-header">
          <div class="details-title">结算详情</div>
        </div>
        <!--团队详情-->
        <div class="details-view flex-column">
          <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane label="佣金列表" name="1">
              <avue-crud
                :data="rebateData"
                :option="rebateOption"
              >
              </avue-crud>
            </el-tab-pane>
            <el-tab-pane label="操作日志" name="2">
              <avue-crud
                :data="logData"
                :option="logOption"
              >
              </avue-crud>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title="交易录入/审核"
      :before-close="drawerClose"
      :visible.sync="drawerVisible"
      direction="ttb"
      size="100%"
    >
      <avue-form ref="form" v-model="formDa" :option="formOp" @submit="handleSubmit">
        <template  slot="product_id">
          <el-select
            v-model="formDa.product_id"
            filterable
            remote
            placeholder="请输入关键词"
            :disabled="formOp.disabled"
            @visible-change="productChangeSelect"
            :remote-method="getProduct">
            <el-option
              v-for="item in productList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </template>
        <template slot="rebate_rule_id">
          <el-select
            v-model="formDa.rebate_rule_id"
            filterable
            remote
            placeholder="请输入关键词"
            :disabled="formOp.disabled"
            @visible-change="rebateRuleChangeSelect"
            :remote-method="getProduct">
            <el-option
              v-for="item in rebateRuleList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </template>
        <template slot="referrer">
          <el-select
            v-model="formDa.referrer"
            filterable
            remote
            placeholder="请输入关键词"
            :disabled="formOp.disabled"
            @visible-change="referrerChangeSelect"
            :remote-method="getReferrer">
            <el-option
              v-for="item in memberList"
              :key="item.id"
              :label="item.nickname"
              :value="item.id">
            </el-option>
          </el-select>
        </template>
      </avue-form>
    </el-drawer>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
