import http from '@/utils/http';

/**
 * 交易接口
 */
export default {
  getList: {
    p: 'post,/manage/trading/getlist',
    r: (data) => http({ url: '/manage/trading/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/trading/add',
    r: (data) => http({ url: '/manage/trading/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/trading/update',
    r: (data) => http({ url: '/manage/trading/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/trading/delete',
    r: (data) => http({ url: '/manage/trading/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/trading/getone',
    r: (data) => http({ url: '/manage/trading/getone', method: 'post', data })
  },
  actionLog: {
    p: 'post,/manage/trading/logs',
    r: (data) => http({ url: '/manage/trading/logs', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/trading/export',
    r: (data) => http({ url: '/manage/trading/export', method: 'post', data })
  }
};
