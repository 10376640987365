import http from '@/utils/http';

/**
 * 产品管理
 */
export default {
  getList: {
    p: 'post,/manage/product/getlist',
    r: (data) => http({ url: '/manage/product/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/product/add',
    r: (data) => http({ url: '/manage/product/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/product/update',
    r: (data) => http({ url: '/manage/product/update', method: 'post', data })
  },
  aiupdate: {
    p: 'post,/manage/product/aiupdate',
    r: (data) => http({ url: '/manage/product/aiupdate', method: 'post', data })
  },
  del: {
    p: 'post,/manage/product/delete',
    r: (data) => http({ url: '/manage/product/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/product/getone',
    r: (data) => http({ url: '/manage/product/getone', method: 'post', data })
  },
  exportFile: {
    p: 'post,/system/export/user',
    r: (data) => http({ url: '/system/export/user', method: 'post', responseType: 'blob', data })
  },
  // AI辅助
  AiAssist: {
    p: 'post,/system/export/user',
    r: (data) => http({ url: '/system/export/user', method: 'post', responseType: 'blob', data })
  }
};
