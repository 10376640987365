import http from '@/utils/http';

/**
 * 会员管理
 */
export default {
  getList: {
    p: 'post,/manage/member/getlist',
    r: (data) => http({ url: '/manage/member/getlist', method: 'post', data })
  },
  update: {
    p: 'post,/manage/member/update',
    r: (data) => http({ url: '/manage/member/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/member/delete',
    r: (data) => http({ url: '/manage/member/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/member/getone',
    r: (data) => http({ url: '/manage/member/getone', method: 'post', data })
  },
  getChild: {
    p: 'post,/manage/member/child',
    r: (data) => http({ url: '/manage/member/child', method: 'post', data })
  },
  exportFile: {
    p: 'post,/manage/member/export',
    r: (data) => http({ url: '/manage/member/export', method: 'post', data })
  },
  sendTplMsg: {
    p: 'post,/manage/member/wxtpl/send',
    r: (data) => http({ url: '/manage/member/wxtpl/send', method: 'post', data })
  }
};
